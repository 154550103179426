import {
  CreateDriverAnnouncementRequestInterface,
  UpdateDriverAnnouncementRequestInterface,
  UploadDriverAnnouncementRequestInterface,
} from '@/data/payload/contracts/DriverAnnouncementRequest'

export class CreateDriverAnnouncementRequest
  implements CreateDriverAnnouncementRequestInterface {
  public announcementTitle?: string
  public announcementBody?: string
  public announcementImage?: string | null
  public startAt?: string
  public endAt?: string
  public location?: string
  public courierTypeValid?: string

  constructor(
    announcementTitle?: string,
    announcementBody?: string,
    announcementImage?: string | null,
    startAt?: string,
    endAt?: string,
    location?: string,
    courierTypeValid?: string
  ) {
    this.announcementTitle = announcementTitle
    this.announcementBody = announcementBody
    this.announcementImage = announcementImage
    this.startAt = startAt
    this.endAt = endAt
    this.location = location
    this.courierTypeValid = courierTypeValid
  }

  public toPayload(): string {
    return JSON.stringify({
      announcement_title: this.announcementTitle,
      announcement_body: this.announcementBody,
      announcement_image_url: this.announcementImage || undefined,
      start_at: this.startAt,
      end_at: this.endAt,
      location: this.location,
      courier_type_valid: this.courierTypeValid,
    })
  }
}

export class UpdateDriverAnnouncementRequest
  implements UpdateDriverAnnouncementRequestInterface {
  public announcementTitle?: string
  public announcementBody?: string
  public announcementImage?: string | null
  public startAt?: string
  public endAt?: string
  public location?: string
  public courierTypeValid?: string

  constructor(
    announcementTitle?: string,
    announcementBody?: string,
    announcementImage?: string | null,
    startAt?: string,
    endAt?: string,
    location?: string,
    courierTypeValid?: string
  ) {
    this.announcementTitle = announcementTitle
    this.announcementBody = announcementBody
    this.announcementImage = announcementImage
    this.startAt = startAt
    this.endAt = endAt
    this.location = location
    this.courierTypeValid = courierTypeValid
  }

  public toPayload(): string {
    return JSON.stringify({
      announcement_title: this.announcementTitle,
      announcement_body: this.announcementBody,
      announcement_image_url: this.announcementImage || undefined,
      start_at: this.startAt,
      end_at: this.endAt,
      location: this.location,
      courier_type_valid: this.courierTypeValid,
    })
  }
}

export class UploadDriverAnnouncementRequest
  implements UploadDriverAnnouncementRequestInterface {
  public images?: Blob | File | null

  constructor(images?: Blob | File | null) {
    this.images = images
  }

  public toPayload(): FormData {
    const form = new FormData()

    form.append('images', this.images as Blob)

    return form
  }
}
