
























































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import FileInput from '@/app/ui/components/FileInput/index.vue'
import ImageUpload from '@/app/ui/components/ImageUpload/index.vue'
import controller, {
  Dropdown,
} from '@/app/ui/controllers/DriverAnnouncementController'
import { Utils } from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import dayjs from 'dayjs'
import { DriverAnnouncement } from '@/domain/entities/DriverAnnouncement'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'
import routeController from '@/app/ui/controllers/RouteController'
import { LetterCode } from '@/domain/entities/Route'

interface IDriverAnnouncement {
  id: number
  announcementTitle: string
  announcementBody: string
  announcementImage: string
  startAt: string
  endAt: string
  location: string
  announcementCourierType: string
  imageUrl: string
}

interface Form {
  announcementRawImage: File[] | Array<undefined>[] | string[] | undefined
  announcementTitle: string
  announcementBody: string
  announcementImage?: string | null
  startAt: string
  endAt: string
  location: Dropdown[]
  courierTypeValid: Dropdown | null
  ctaName?: string
  ctaUrl?: string
}

interface ErrorField {
  announcementTitle: boolean
  announcementBody: boolean
  announcementImage: boolean
  startAt: boolean
  endAt: boolean
  ctaName: boolean
  courierTypeValid: boolean
}

@Component({
  components: {
    TextInput,
    Button,
    FileInput,
    Modal,
    LoadingOverlay,
    DateTimePicker,
    DropdownSelect,
    ImageUpload,
    IconWarningTriangle,
    MultiselectV2
  },
})
export default class EditDriverAnnouncementPage extends Vue {
  controller = controller
  routeController = routeController
  form: Form = {
    announcementRawImage: [],
    announcementTitle: '',
    announcementBody: '',
    announcementImage: null,
    startAt: '',
    endAt: '',
    location: [],
    courierTypeValid: null,
    ctaName: '',
    ctaUrl: '',
  }
  errorField: ErrorField = {
    announcementTitle: false,
    announcementBody: false,
    announcementImage: false,
    endAt: false,
    startAt: false,
    ctaName: false,
    courierTypeValid: false
  }
  confirmationModal = false
  successModal = false
  preview = ''
  minDate = ''

  districtOptions: Dropdown[] = this.controller.districts

  created(): void {
    controller.getAnnouncementDetail(Number(this.$route.params.id))
    routeController.getLetterCodes()
  }

  get courierTypeOptions(): Dropdown[] {
    return controller.constant.courierTypeOptions
  }

  get announcementImageErrorMessage(): string {
    return this.errorField.announcementImage ? 'Maks. size : 2 MB, Format : .jpg .png .jpeg Rekomendasi Dimensi 2 : 4, (contoh: 200px X 800px)' : ''
  }

  private onSearchDistrict(query: string): void {
    let res: Array<Dropdown> = []

    if (query === '') {
      this.districtOptions = routeController.letterCodes.map((route) => {
        return {
          label: <string>route.lc,
          value: <string>route.lc
        }
      })
    }

    routeController.letterCodes.forEach((route) => {
      if ((<string>route.lc).toUpperCase().includes(query.toUpperCase())) {
        res.push({
          label: <string>route.lc,
          value: <string>route.lc
        })
      }
    })

    this.districtOptions = res
  }


  private async changeImage(file: File): Promise<void> {
    const image = await controller.uploadImage(file)
    this.form.announcementRawImage = [file]
    this.form.announcementImage = image as string
    this.errorField.announcementImage = false
  }

  private removeErrorField(key: string): void {
    this.errorField[key as keyof ErrorField] = false
  }

  private onRemoveAnnouncementImage(): void {
    this.form.announcementRawImage = []
    this.form.announcementImage = ''
  }

  private onErrorAnnouncementImage(): void {
    this.errorField.announcementImage = true
  }

  private openConfirmationModal(): void {
    this.confirmationModal = Boolean(!this.validateAnnouncementForm())
  }

  private validateAnnouncementForm(): boolean {
    return Object.keys(this.errorField).map((key: string) => {
      if (key === 'ctaName') {
        if (Boolean(this.form.ctaUrl) && Boolean(!this.form[key as keyof Form])) {
          this.errorField[key as keyof ErrorField] = true
          return true
        }
      } else if (key === 'announcementImage') {
        return false
      } else {
        return this.errorField[key as keyof ErrorField] = Boolean(!this.form[key as keyof Form])
      }
    }).includes(true)
  }

  private updateAnnouncement(): void {
    controller.updateAnnouncement({
      id: Number(this.$route.params.id),
      announcementTitle: this.form.announcementTitle,
      announcementBody: this.announcementBody,
      announcementImage: this.form.announcementImage,
      startAt: dayjs(this.form.startAt).toISOString(),
      endAt: dayjs(this.form.endAt).toISOString(),
      location:
        this.form.location.length === 0
          ? 'ALL'
          : this.form.location.map(location => location.value).join(','),
      courierTypeValid: <string>this.form.courierTypeValid?.value
    })
  }

  get announcementBody(): string {
    let body = Utils.escapeHTML(this.form.announcementBody).replace(
      /(?:\r\n|\r|\n)/g,
      '<br/>'
    )

    if (this.form.ctaUrl || this.form.ctaName) {
      const style = [
        'background-color: #B82025',
        'border: none',
        'color: white',
        'padding: 10px 32px',
        'text-align: center',
        'text-decoration: none',
        'display: inline-block',
        'font-size: 16px',
        'margin: 4px 2px',
        'cursor: pointer',
        'border-radius: 8px',
        'font-weight: 500',
      ]
      body += `<br/><br/><div style="text-align: center"><a style="${style.join(
        ';'
      )}" href="${this.form.ctaUrl}">${this.form.ctaName ||
        this.form.ctaUrl}</a></div>`
    }

    return body
  }

  get announcementDetail(): DriverAnnouncement {
    return controller.announcementDetail
  }

  @Watch('announcementDetail')
  private updatedAnnouncementDetail(announcement: IDriverAnnouncement): void {
    const parseBodyContent = this.parseBody(
      announcement.announcementBody as string
    )

    this.form = {
      announcementRawImage: [],
      announcementTitle: announcement.announcementTitle as string,
      announcementBody: parseBodyContent.body,
      courierTypeValid:
        <Dropdown>controller.constant.courierTypeOptions.find(
          option => option.value === announcement.announcementCourierType
        ),
      announcementImage: null,
      startAt: dayjs(announcement.startAt).format(),
      endAt: dayjs(announcement.endAt).format(),
      location: announcement.location
        ? (announcement.location
            .split(',')
            .filter(location => location !== 'ALL')
            .map(location => ({
              value: location,
              label: location,
            })) as Dropdown[])
        : [],
      ctaName: parseBodyContent.ctaName,
      ctaUrl: parseBodyContent.ctaUrl,
    }

    this.preview = announcement.imageUrl as string
    this.form.announcementRawImage = announcement.imageUrl ? [announcement.imageUrl as string] : []
  }

  private parseBody(
    html: string
  ): { body: string; ctaName: string; ctaUrl: string } {
    const matches = html.match(
      /(.*)?<br\/><br\/>(?:<div style="text-align: center">)?<a(?:.*)?href="(.*)">(.*)<\/a>(?:<\/div>)?/
    )

    const parseContent = (text: string): string =>
      text
        .replace(/<br\/>/g, '\n')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")

    if (matches) {
      return {
        body: parseContent(matches[1]),
        ctaName: matches[3],
        ctaUrl: matches[2],
      }
    }

    return {
      body: parseContent(html),
      ctaName: '',
      ctaUrl: '',
    }
  }

  @Watch('form.startAt')
  getMinEndDate(value: string): void {
    if(value !== dayjs(controller.announcementDetail.startAt).format()) {
      this.minDate = dayjs(value).format("YYYY-MM-DD[T]HH:mm:ss.SSS")
    }
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.controller.setIsUpdateDriverAnnouncementSuccess(false)
    this.$router.push({ name: 'DriverAnnouncementList' })
  }

  get today(): string {
    return dayjs().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
  }

  get isUploading(): boolean {
    return controller.isUploading
  }

  @Watch('routeController.letterCodes')
  onLetterCodesChanged(data: LetterCode[]): void {
    this.districtOptions = data.map((route) => {
      return {
        label: <string>route.lc,
        value: <string>route.lc
      }
    })
  }

  @Watch('form.announcementTitle')
  setAnnouncementTitle(title: string): void {
    // remove script tag
    this.form.announcementTitle = Utils.removeScriptTags(title)

    // remove special character except emojis
    this.form.announcementTitle = Utils.removeSpecialCharWithEmojis(this.form.announcementTitle)
  }

  @Watch('form.announcementBody')
  setAnnouncementBody(body: string): void {
    // remove script tag
    this.form.announcementBody = Utils.removeScriptTags(body)

    // remove special character except emojis
    this.form.announcementBody = Utils.removeSpecialCharWithEmojis(this.form.announcementBody)
  }

  @Watch('form.ctaName')
  setCTAName(name: string): void {
    // remove script tag
    this.form.ctaName = Utils.removeScriptTags(name)

    // remove special character except emojis
    this.form.ctaName = Utils.removeSpecialCharWithEmojis(this.form.ctaName)
  }

  @Watch('controller.isUpdateDriverAnnouncementSuccess')
  private setSuccessModal(value: boolean): void {
    if (value) {
      this.confirmationModal = false
      this.successModal = true
    }
  }

  beforeDestroy(): void {
    this.controller.setIsUpdateDriverAnnouncementSuccess(false)
  }
}
