import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  CreateDriverAnnouncementRequest,
  UpdateDriverAnnouncementRequest,
  UploadDriverAnnouncementRequest
} from '@/data/payload/api/DriverAnnouncementRequest'
import { DriverAnnouncementPresenter } from '@/app/ui/presenters/DriverAnnouncementPresenter'
import { DriverAnnouncement, DriverAnnouncements } from '@/domain/entities/DriverAnnouncement'
import { Pagination } from '@/domain/entities/Pagination'
import { DRIVER_ANNOUNCEMENT_PAGINATION } from '@/app/infrastructures/misc/Constants/pagination'
import { RoutePresenter } from '../presenters/RoutePresenter'
import { OriginRoute } from '@/domain/entities/Route'

export interface Dropdown {
  value: string
  label: string
}

export interface DriverAnnouncementState {
  isLoading: boolean
  isLoadingDistrict: boolean
  paginationData: Pagination
  announcementData: DriverAnnouncement[]
  announcementDetail: DriverAnnouncement
  districts: Dropdown[]
  isUploading: boolean
  isCreateDriverAnnouncementSuccess: boolean
  isUpdateDriverAnnouncementSuccess: boolean
  isDeleteDriverAnnouncementSuccess: boolean
}

interface Form {
  announcementTitle: string
  announcementBody: string
  announcementImage?: string | null
  startAt: string
  endAt: string
  location: string
  courierTypeValid: string
}

interface EditForm extends Form {
  id: number
}

@Module({ namespaced: true, store, name: 'driverAnnouncement', dynamic: true })
class DriverAnnouncementController extends VuexModule implements DriverAnnouncementState {
  private presenter: DriverAnnouncementPresenter = container.resolve(DriverAnnouncementPresenter)
  private routePresenter: RoutePresenter = container.resolve(RoutePresenter)
  public isLoading = false
  public isLoadingDistrict = false
  public paginationData = new Pagination(1, DRIVER_ANNOUNCEMENT_PAGINATION)
  public announcementData: DriverAnnouncement[] = []
  public announcementDetail = new DriverAnnouncement()
  public districts: Dropdown[] = []
  public isUploading = false;
  public isCreateDriverAnnouncementSuccess = false;
  public isUpdateDriverAnnouncementSuccess = false;
  public isDeleteDriverAnnouncementSuccess = false;

  public constant = {
    courierTypeOptions: [
      {
        value: 'ALL',
        label: 'Driver Apps'
      },
      {
        value: 'KVP',
        label: 'KVP'
      },
      {
        value: 'POS',
        label: 'Kurir POS'
      },
      {
        value: 'TRUCK',
        label: 'Trucking Apps'
      }
    ]
  }

  @Action({ rawError: true })
  public createAnnouncement(form: Form) {
    this.setLoading(true)

    this.presenter
      .create(
        new CreateDriverAnnouncementRequest(
          form.announcementTitle,
          form.announcementBody,
          form.announcementImage,
          form.startAt,
          form.endAt,
          form.location,
          form.courierTypeValid
        )
      )
      .then(() => {
        this.setIsCreateDriverAnnouncementSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Driver Announcement Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateAnnouncement(form: EditForm) {
    this.setLoading(true)

    this.presenter
      .update(
        form.id,
        new UpdateDriverAnnouncementRequest(
          form.announcementTitle,
          form.announcementBody,
          form.announcementImage,
          form.startAt,
          form.endAt,
          form.location,
          form.courierTypeValid
        )
      )
      .then(() => {
        this.setIsUpdateDriverAnnouncementSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Driver Announcement Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getAnnouncementList(params: Record<string, string | number | undefined>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setDriverAnnouncementData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Merchant Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getAnnouncementDetail(id: number) {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(res => {
        this.setDriverAnnouncementDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Driver Announcement Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async deleteAnnouncement(
    payload: {
      id: number,
      params: Record<string, string | number | undefined>
    }
  ): Promise<boolean> {
    this.setLoading(true)
    const result = await this.presenter
      .delete(payload.id)
      .then(() => {
        this.setIsDeleteDriverAnnouncementSuccess(true)
        this.getAnnouncementList(payload.params)
        return true
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete Driver Announcement Failed',
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        return false
      })
      .finally(() => {
        this.setLoading(false)
      })
    return result
  }

  @Action({ rawError: true })
  public getDistrictList() {
    this.setLoadingDistrict(true)
    this.routePresenter
      .getAllCity()
      .then((cities) => {
        this.setDistrictList(cities)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch District List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingDistrict(false)
      })
  }

  @Action({ rawError: true })
  public async uploadImage(image: File | Blob) {
    this.setUploading(true)
    return this.presenter
      .uploadImage(new UploadDriverAnnouncementRequest(image))
      .then((url) => {
        return url
      })
      .catch(error => {
        Vue.notify({
          title: 'Upload Image Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setUploading(false)
      })
  }

  @Mutation
  private setDriverAnnouncementData(announcements: DriverAnnouncements) {
    this.paginationData = announcements.pagination as Pagination
    this.announcementData = announcements.data as DriverAnnouncement[]
  }

  @Mutation
  private setDriverAnnouncementDetail(announcement: DriverAnnouncement) {
    this.announcementDetail = announcement
  }

  @Mutation
  private setDistrictList(origins: OriginRoute[]) {
    this.districts = origins.map((origin) => ({
      value: origin.letterCode,
      label: origin.letterCode
    })) as Dropdown[]
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setUploading(bool: boolean) {
    this.isUploading = bool
  }

  @Mutation
  private setLoadingDistrict(bool: boolean) {
    this.isLoadingDistrict = bool
  }

  @Mutation
  public setIsCreateDriverAnnouncementSuccess(bool: boolean) {
    this.isCreateDriverAnnouncementSuccess = bool
  }

  @Mutation
  public setIsUpdateDriverAnnouncementSuccess(bool: boolean) {
    this.isUpdateDriverAnnouncementSuccess = bool
  }

  @Mutation
  public setIsDeleteDriverAnnouncementSuccess(bool: boolean) {
    this.isDeleteDriverAnnouncementSuccess = bool
  }
}

export default getModule(DriverAnnouncementController)
